import { AppEnv, AppInstance } from 'src/core/utils/instance.utils';

export const env: AppEnv = AppEnv.TEST;
export const instance: AppInstance = AppInstance.IS;
export const api_url = 'https://api.test.ekareinc.com/IS/api/3.0/';
export const router_basename = '/';
export const api_pass = '7c89ad287e0ba21d58647201dc8b3c69';
export const auto_logout_delay_minutes = 10;
export const auto_logout_delay = auto_logout_delay_minutes * 60 * 1000;
export const verify_session_interval_secondes = 30;
export const verify_session_interval = verify_session_interval_secondes * 1000;
export const privacy_policy_url = 'https://ekare.ai/privacy-policy/';
export const terms_conditions_url = 'https://ekare.ai/terms-and-conditions/';
export const app_version = '3.0.0';
export const app_version_in_udi = '030000';
export const udi_date = '20250101';
export const udiKey = '(01)00860003459850(10)';

// Android URLs for Mfa
export const Google_android = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
export const Microsoft_android = 'https://play.google.com/store/apps/details?id=com.azure.authenticator';
export const LastPass_android = 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator';

// iOS URLs for Mfa
export const Google_ios = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
export const Microsoft_ios = 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458';
export const LastPass_ios = 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004';
export const mfa_secret_key =
  'QGKKBl2/jbf83sj17dIE3jXtazS+o8GxyvRYbNlyuafO8uRQ1j+Y423OPHGfZSrPGJlHpL630Tee69/vO1oULQKmi39i5d379GZsEDCF7xwOdPG1XKTT+r5OVI1kSngOXUBdBKbbKrCCy6OBgqxMOHCqoYvm1D/ePdN09nCOgEqujnUGd5wfal+wYkfodS7xWvhYFAVHUhOIgE1K/fk2hN8I8l1Ok2xMG89JpPNe860gvVvc5ZglJfvlavfbC1/MWX+DZ+3K5izFNRr4sGjC4W77Xrrj+6q2JI3wmtrD6Jb6SMTPXwm6ufO51tM3PNb1YeftdQsrXovwy8uuuROsxslWbIn9jHTQZDzhWMvSQws=';

// Parse server
export const ParseAppID = '1rubrES0wi9u4uCufaDastog5phLstLtrA2rAsotIFudriRus4gA6habruBE1ri8';
export const ParseServerURL = 'https://parse.test.ekareinc.com/parse';

//healbot
export const healbot_url = 'https://healbot.ekare.ai';
